import React from 'react';
import { Link } from 'gatsby';
import Layout from '../App/components/layout';

import * as ROUTES from '../constants/routes';


const PasswordChanged = () => (
    <Layout>
        <div className="wrapper">
            <h1>We have updated your password.</h1>
                
            <p>We have logged you out, please <Link to={ROUTES.SIGN_IN} >sign in</Link> again</p>

        </div>
    </Layout>
)
export default PasswordChanged;